import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Container, Input } from '@material-ui/core'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import CourseWrapper from '../../components/CourseWrapper'

import { PageTitle } from '../../components/PageTitle'
import { SectionTitle, SearchBar } from '../../styles/pages/presencial'

export default function GraduacaoPresencialLagartoPage(props) {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  const [filteredCourses, setFilteredCourses] = useState(edges)
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    const posts = filteredCourses.filter(({ node }) =>
      node.frontmatter.name.toLowerCase().includes(query.toLowerCase())
    )
    setSearchResults(posts)
  }, [query.normalize("NFC").toLowerCase()])

  return (
    <Layout>
      <SEO title="Graduação Presencial Faculdade Jardins All - Lagarto" />

      <Container style={{ marginTop: 70 }}>
        <PageTitle>Graduação Presencial Faculdade Jardins All - Lagarto</PageTitle>

      </Container>
      <Container disableGutters={true} style={{ marginTop: 60 }}>
        <SectionTitle>Cursos</SectionTitle>

        <SearchBar>
          <Input
            placeholder="Pesquisar curso"
            value={query}
            onChange={e => setQuery(e.target.value)}
            style={{ padding: 6, borderRadius: 5, border: '2px solid' }}
          />
        </SearchBar>

        <div>
          {searchResults.map((course) => (
            <CourseWrapper
              name={course.node.frontmatter.name}
              duration={course.node.frontmatter.duration}
              price={course.node.frontmatter.price}
              subscribe={course.node.frontmatter.subscribe}
              link={course.node.fields.slug}
              key={searchResults.indexOf(course)}
            />
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___name], order: ASC }
      filter: { frontmatter: { category: { eq: "graduacao all-lagarto" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            price
            duration
            subscribe
          }
        }
      }
    }
  }
`
